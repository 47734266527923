<template>
  <div>
    <fw-panel title="Personaliza a tua equipa" :boxed="boxed ? 'lg' : false" :class="{ 'my-5': boxed }">
      <div class="flex gap-5 mb-5">
        <div class="text-center w-1/3">
          <Uploader
            reference-id="team-image"
            allowed="images"
            bucket-type="image"
            :is-docked="true"
            :is-custom="true"
            :clear-after="true"
            :limit="1"
            :path-to-upload="photoUploaderUrl"
            @upload="$emit('update-team-image', $event)"
          >
            <div slot="navbar">
              <div class="flex flex-col items-center p-5">
                <div class="h-36 w-36 relative">
                  <TeamBackground
                    class="absolute overflow-visible h-36 w-36 drop-shadow-xl top-0 left-0"
                    :style="{ color: team.color ?? '#EFEFEF' }"
                  />
                  <div
                    v-if="!team.logo"
                    class="h-24 w-24 rounded-lg text-2xl flex justify-center items-center uppercase text-opacity-70 font-bold overflow-hidden absolute top-6 left-6"
                  >
                    <TeamImagePlaceholder class="h-24 w-24" />
                  </div>

                  <img
                    v-else
                    :src="getTeamLogoUrl(team.logo)"
                    class="transition-opacity h-24 w-24 object-cover object-center rounded-lg text-2xl flex justify-center items-center uppercase text-opacity-70 font-bold absolute top-6 left-6"
                    :class="logoLoaded ? 'opacity-100' : 'opacity-0'"
                    @load="logoLoaded = true"
                  />
                  <div
                    v-if="!logoLoaded && team.logo"
                    class="transition-all h-24 w-24 rounded-lg absolute top-6 left-6 flex justify-center items-center text-white"
                    :style="{ backgroundColor: team.color ?? '#EFEFEF' }"
                  >
                    <fw-icon-loading class="animate-spin w-10 h-10" />
                  </div>
                </div>

                <a href="#" class="text-xs font-semibold text-gray-600 mt-4">Define uma imagem</a>
              </div>
            </div>
          </Uploader>
          <a v-if="team.logo" href="#" class="text-xs text-red-500" @click="$emit('remove-logo')">Remover</a>
        </div>
        <div class="flex-1 flex gap-1 flex-col justify-around">
          <div>
            <fw-label>Indica um nome</fw-label>
            <b-input
              v-model="team.name"
              :class="{
                error: teamErrors.name.$error
              }"
              :minlength="1"
              :maxlength="50"
            ></b-input>
            <fw-tip v-if="teamErrors.name.$error" error>Insira um nome para a sua equipa </fw-tip>
          </div>
          <div>
            <fw-label>Escolhe uma cor</fw-label>
            <div class="flex gap-3">
              <button
                v-for="(color, c) in availableColors"
                :key="'color_' + c"
                :style="{ backgroundColor: color }"
                :class="{ 'border-gray-200': color != team.color, ' border-primary border-2': color == team.color }"
                class="h-10 w-10 border rounded-full"
                role="button"
                @click="$emit('select-color', color)"
              ></button>

              <!--
                <button
                v-if="team.color != null"
                role="button"
                class="h-10 w-10 border-2 rounded-full border-gray-100 bg-gray-50 flex justify-center items-center"
                @click="$emit('select-color', null)"
              >
                <fw-icon-close class="text-gray-400"></fw-icon-close>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel v-if="isAcademic" title="Núcleo de estudantes" custom-class="bg-white p-5 mb-5">
      <fw-label>Indique o núcleo de estudantes ao qual esta equipa pertence</fw-label>
      <b-select v-model="team.group_key" placeholder="Selecione um grupo" @blur="$emit('save-team')">
        <option v-for="group in teamGroups" :key="group.key" :value="group.key">{{ group.name }}</option>
      </b-select>

      <fw-tip v-if="teamErrors.group_key.$error" error>
        Indique o núcleo de estudantes
      </fw-tip>
    </fw-panel>

    <fw-panel v-if="showAddMembers" title="Convida pessoas para a tua equipa">
      <fw-panel
        :title="team.players?.length > 0 ? 'Convites definidos' : ''"
        :title-size="'h4'"
        :counter="team.players?.length ?? 0"
      >
        <div v-if="team.players && team.players.length > 0" class="bg-white rounded-md mb-4">
          <div
            v-for="(player, p) in team.players"
            :key="p"
            class="flex items-center gap-3 py-2 px-3"
            :class="{
              'border-b border-gray-100': p != team.players.length - 1
            }"
          >
            <fw-icon-smile class="h-6 w-6" />
            <div class="flex-1 font-semibold">{{ player.email }}</div>
            <div v-if="player.key == team.leader.key">
              <div
                class="bg-primary opacity-90 rounded py-1 pl-1.5 pr-2 flex gap-2 text-white font-bold text-xs items-center"
              >
                <fw-icon-team class="text-white h-4 w-4"></fw-icon-team> Líder
              </div>
            </div>

            <fw-button
              v-if="management || player.key != user.key"
              class="light"
              @click.native="$emit('remove-player', p)"
              >Remover</fw-button
            >
          </div>
        </div>

        <fw-tip v-if="teamErrors.players.$error" error>
          <span v-if="!teamErrors.players.min"
            >Uma equipa tem de ter pelo menos {{ teamErrors.players.$params.min.min }} elementos. Apenas
            {{ team.players?.length ?? 1 }} de {{ teamErrors.players.$params.min.min }} adicionados.</span
          >
          <span v-else-if="!teamErrors.players.max"
            >Uma equipa só pode ter no máximo {{ teamErrors.players.$params.max.max }} elementos.
            {{ team.players?.length ?? 1 }} de {{ teamErrors.players.$params.max.max }} adicionados.</span
          >
          <span v-else>Insira alguns elementos na sua equipa.</span>
        </fw-tip>

        <div class="border border-dashed border-primary p-5 rounded-2xl flex flex-col gap-3 my-2 bg-white">
          <div class="text-primary font-bold">Convidar novo membro</div>
          <div class="text-gray-500 font-semibold">
            Indique um email {{ isAcademic ? ' institucional' : '(externo ou institucional)' }} da pessoa que pretende
            convidar para a sua equipa.
          </div>
          <div>
            <form class="flex gap-3 max-w-lg" @submit.prevent="addPlayer">
              <b-input v-model="tmpTeamMember" class="flex-1"></b-input>
              <fw-button type="primary" :disabled="saving" role="submit">Convidar</fw-button>
            </form>
            <fw-tip v-if="$v.tmpTeamMember.$error" error>
              <span v-if="!$v.tmpTeamMember.isUnique">O e-mail já faz parte dos convites definidos.</span>
              <span v-else-if="!$v.tmpTeamMember.required">Insira um e-mail da pessoa que pretende convidar.</span>
              <span v-else>O e-mail não é válido.</span>
            </fw-tip>
          </div>

          <fw-panel-info type="orange" boxed clean icon>
            Os convites para os membros da equipa serão enviados após concluires a inscrição da equipa.<br />Para que
            uma equipa seja aprovada, todos os membros terão de aceitar o convite.
          </fw-panel-info>
        </div>
      </fw-panel>
    </fw-panel>
  </div>
</template>

<script>
import TeamImagePlaceholder from '@/components/icons/TeamImagePlaceholder'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import TeamBackground from '@/components/icons/TeamBackground'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import { TEAM_COLORS } from '@/utils/index'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    Uploader,
    TeamImagePlaceholder,
    TeamBackground
  },
  props: {
    saving: {
      type: Boolean,
      default: true
    },
    teamErrors: {
      type: Object
    },
    isAcademic: {
      type: Boolean,
      default: false
    },
    team: {
      type: Object,
      default: () => {
        return {
          key: null,
          group_key: null,
          tournament_key: null,
          name: null,
          logo: null,
          leader: null,
          color: null,
          status: 'draft',
          players: [],
          submitted_at: null,
          confirmed_at: null,
          denied_at: null,
          deleted_at: null
        }
      },
      required: true
    },
    teamGroups: {
      type: Array,
      default: () => []
    },
    showAddMembers: {
      type: Boolean,
      default: true
    },
    boxed: {
      type: Boolean,
      default: true
    },
    management: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      photoUploaderUrl: FwEnvConfig.apiUrlStorage + '/v1/file',
      availableColors: TEAM_COLORS,
      tmpTeamMember: '',
      logoLoaded: false
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    api() {
      return this.$store.state.api.base
    }
  },

  watch: {
    'team.logo'(newVal, oldVal) {
      if (oldVal && newVal?.key != oldVal?.key) {
        this.logoLoaded = false
      }
    }
  },

  validations() {
    return {
      tmpTeamMember: {
        required,
        valid: email,
        isUnique(value) {
          if (value === '') return true

          return !this.team.players.find(el => el.email == value || (el.number && el.number == value))
        }
      }
    }
  },
  methods: {
    addPlayer() {
      // Can be registered ID users or not
      this.$v.$touch()
      if (!this.$v.tmpTeamMember.$invalid) {
        this.$emit('add-player', { email: this.tmpTeamMember })
        this.$v.$reset()
        this.tmpTeamMember = ''
      }
    },

    getTeamLogoUrl(image) {
      if (!image) return
      return ServiceStorage.getImageUrl(image, 'max2k', null)
    }
  }
}
</script>

<i18n>
    {
      "en": {
        "phoneCountry": "Country code",
        "phone": "Phone number",
        "address": "Address",
        "postalCode": "Postal code",
        "locality": "Locality",
        "country": "Country",
        "errors": {
          "required": {
            "phoneNumber": "Phone number is required",
            "address": "Address is required",
            "postalCode": "Postal code is required",
            "locality": "Locality is required",
            "country_code": "Country is required",
            "nif": "NIF is required",
            "phoneCountry": "Country code is required"
          },
          "invalid": {
            "phoneNumber": "Phone number is invalid",
            "address": "Address is invalid",
            "postalCode": "Postal code is invalid",
            "locality": "Locality is invalid",
            "country_code": "Country is invalid",
            "nif": "NIF is invalid",
            "phoneCountry": "Country code is invalid"
          }
        }
      },
      "pt": {
        "phoneCountry": "Indicativo",
        "phone": "Número de telemóvel",
        "address": "Morada",
        "locality": "Localidade",
        "country": "País",
        "postalCode": "Código postal",
        "errors": {
          "required": {
            "phoneNumber": "O número de telemóvel é obrigatório",
            "address": "A morada é obrigatória",
            "postalCode": "O código postal é obrigatório",
            "locality": "A localidade é obrigatória",
            "country_code": "O país é obrigatório",
            "nif": "O NIF é obrigatório",
            "phoneCountry": "O indicativo é obrigatório"
          },
          "invalid": {
            "phoneNumber": "O número de telemóvel é inválido",
            "address": "A morada é inválida",
            "postalCode": "O código postal é inválido",
            "locality": "A localidade é inválida",
            "country_code": "O país é inválido",
            "nif": "O NIF é inválido",
            "phoneCountry": "O indicativo é inválido"
          }
        }
      }
    }
</i18n>

<style>
.update-modal-uploader .file-uploads {
  @apply w-full mt-1;
}
</style>
