<template>
  <fw-layout back-to="/" mobile-ready :main-sidebar="false" loading-title="UC Competitions" :loading="loadingInitial">
    <template #main-content>
      <div class="xl:max-w-screen-lg mx-auto px-2 md:px-5 flex flex-col gap-5">
        <div v-if="step == 'submitted'" class="flex flex-col gap-5 mb-5 mt-20 items-center">
          <CardTeam :team="team" class="overflow-visible" size="lg" />
          <div class="max-w-prose text-center mt-5">
            <fw-heading v-if="isIndividual" class="mb-4">{{ $t('teamSuccessfullySubmitted') }}</fw-heading>
            <fw-heading v-else class="mb-4">{{ $t('teamSuccessfullyRegistered') }}</fw-heading>
            <p v-if="!isIndividual" class="text-base font-semibold text-yellow-600">
              {{ $t('teamRegisteredInstructions') }}
            </p>
          </div>

          <div class="flex gap-5 justify-center text-center text-gray-600  mt-3 mb-5">
            <div>
              <fw-label>{{ $t('edition') }}</fw-label>
              <div class="font-bold">
                {{ edition.name }}
              </div>
            </div>

            <div>
              <fw-label>{{ $t('league') }}</fw-label>
              <div class="font-bold">{{ league?.title?.[language] }}</div>
            </div>
            <div>
              <fw-label>{{ $t('tournament') }}</fw-label>
              <div class="font-bold">{{ tournament?.title?.[language] }}</div>
            </div>
            <div>
              <fw-label>{{ $t('modality') }}</fw-label>
              <div class="font-bold">
                {{ `${sport?.modality?.title?.[language]} ${sport?.category?.[language] ?? ''}` }}
              </div>
            </div>
            <div>
              <fw-label>{{ $t('gender') }}</fw-label>
              <div class="font-bold">
                {{ genders[tournament?.gender ?? 'U'][language] }}
              </div>
            </div>
          </div>

          <fw-button type="primary" @click.native="goToMyTeam()">{{ $t('seeTeam') }}</fw-button>
          <fw-button type="xlight" @click.native="goHome()">{{ $t('backHome') }}</fw-button>
        </div>

        <template v-else>
          <fw-panel :title="'Criar equipa'" featured>
            <div v-if="!loading" class="flex gap-6 border-b border-gray-300 py-3">
              <button
                role="button"
                class="flex flex-col gap-1 items-start cursor-pointer"
                :class="{
                  'text-primary': step == 'create_team',
                  'text-gray-500 hover:text-primary': step != 'create_team'
                }"
                @click="goToStep('create_team')"
              >
                <div class="flex-shrink-0 whitespace-nowrap flex items-center gap-2 text-xs font-semibold">
                  <span>{{ $t('step') }} 1</span>
                  <fw-icon-checkbox-circle-solid v-if="firstStepDone" class="h-4 w-4 text-primary" />
                </div>
                <div class="flex-shrink-0 whitespace-nowrap font-bold">{{ $t('buildYourTeam') }}</div>
              </button>

              <button
                :disabled="!firstStepDone"
                role="button"
                class="flex flex-col gap-1 items-start cursor-pointer"
                :class="{
                  'text-primary': step == 'personal_info',
                  'text-gray-500 hover:text-primary': step != 'personal_info',
                  'cursor-not-allowed opacity-70': !firstStepDone
                }"
                @click="goToStep('personal_info')"
              >
                <div class="flex-shrink-0 whitespace-nowrap flex items-center gap-2 text-xs font-semibold">
                  <span>{{ $t('step') }} 2</span>
                  <fw-icon-checkbox-circle-solid v-if="secondStepDone" class="h-4 w-4 text-primary" />
                </div>
                <div class="flex-shrink-0 whitespace-nowrap font-bold">{{ $t('provideYourDetails') }}</div>
              </button>

              <button
                :disabled="!firstStepDone || !secondStepDone"
                class="flex flex-col gap-1 items-start cursor-pointer"
                :class="{
                  'text-primary': step == 'submit',
                  'text-gray-500 hover:text-primary': step != 'submit',
                  'cursor-not-allowed opacity-70': !firstStepDone || !secondStepDone
                }"
                @click="goToStep('submit')"
              >
                <div class="flex-shrink-0 whitespace-nowrap flex items-center gap-1 text-xs font-semibold">
                  {{ $t('step') }} 3
                </div>
                <div class="flex-shrink-0 whitespace-nowrap font-bold">
                  {{ isIndividual ? $t('submitTeam') : $t('createTeam') }}
                </div>
              </button>
            </div>
            <div v-else class="flex gap-5 border-b border-gray-300 pb-3 animate-pulse">
              <div class="flex flex-col gap-2">
                <div class="bg-gray-200 rounded-full w-14 h-3"></div>
                <div class="bg-gray-200 rounded-full w-36 h-4"></div>
              </div>
              <div class="flex flex-col gap-2">
                <div class="bg-gray-200 rounded-full w-14 h-3"></div>
                <div class="bg-gray-200 rounded-full w-36 h-4"></div>
              </div>
              <div class="flex flex-col gap-2">
                <div class="bg-gray-200 rounded-full w-14 h-3"></div>
                <div class="bg-gray-200 rounded-full w-36 h-4"></div>
              </div>
            </div>
          </fw-panel>

          <div v-if="loading" class="flex flex-col gap-4 py-5">
            <div class="bg-gray-200 rounded-full w-44 h-6"></div>
            <div class="bg-gray-200 rounded-2xl h-52"></div>
          </div>

          <PanelPersonalInformationForm
            v-if="step === 'personal_info'"
            ref="personalinfo"
            class="my-5"
            :edition-key="editionKey"
            :league="league"
            :is-valid.sync="userDataIsValid"
            :tournament="tournament"
            :check-terms="true"
            @saved="goNext()"
          />
          <PanelCreateTeam
            v-if="step === 'create_team'"
            ref="createteam"
            :team="team"
            :team-errors="$v.team"
            :saving="saving"
            :is-academic="isAcademic"
            :show-add-members="sport?.min_players > 1"
            :team-groups="teamGroups"
            @update-team-image="updateTeamLogo"
            @remove-logo="removeLogo"
            @select-color="selectColor"
            @remove-player="removeTeamMember"
            @add-player="addTeamMember"
          ></PanelCreateTeam>
          <div v-if="step == 'submit'" class="flex flex-col gap-5 mb-5 mt-10 items-center">
            <div class="text-gray-700 text-xl font-semibold text-center">
              <fw-heading size="h2">Wow!</fw-heading>
              <div class="text-gray-500 text-base">{{ $t('wonderfulTeam') }}</div>
            </div>

            <CardTeam :team="team" class="overflow-visible" size="lg" />
            <div class="flex gap-5 justify-center text-center text-gray-600 text-sm">
              <div>
                <fw-label>{{ $t('edition') }}</fw-label>
                <div class="font-bold">
                  {{ edition.name }}
                </div>
              </div>

              <div>
                <fw-label>{{ $t('league') }}</fw-label>
                <div class="font-bold">{{ league?.title?.[language] }}</div>
              </div>
              <div>
                <fw-label>{{ $t('tournament') }}</fw-label>
                <div class="font-bold">{{ tournament?.title?.[language] }}</div>
              </div>
              <div>
                <fw-label>{{ $t('modality') }}</fw-label>
                <div class="font-bold">
                  {{ `${sport?.modality?.title?.[language]} ${sport?.category?.[language] ?? ''}` }}
                </div>
              </div>
              <div>
                <fw-label>{{ $t('gender') }}</fw-label>
                <div class="font-bold">
                  {{ genders[tournament?.gender ?? 'U'][language] }}
                </div>
              </div>
            </div>
            <div class="text-base text-gray-500 font-medium max-w-xl mx-auto text-center my-3">
              <div v-if="isIndividual">
                Está quase tudo pronto. Já podes submeter a tua inscrição, para que a mesma possa ser validada pela
                organização.
              </div>
              <div v-else>
                Após criares a tua equipa, vamos enviar convites a todos os elementos convidados e assim que os
                participantes tiverem aceitado, podes submeter a equipa para validação.
              </div>
            </div>
            <div>
              <fw-button
                v-if="isIndividual && (team.status == 'draft_1' || team.status == 'draft_2')"
                type="primary"
                size="md"
                wider
                @click.native="submitTeam()"
                >Submeter equipa</fw-button
              >
              <fw-button
                v-else-if="!isIndividual && team.status == 'draft_1'"
                type="primary"
                size="md"
                wider
                @click.native="registerTeam()"
                >{{ $t('signUpTeam') }}</fw-button
              >
              <fw-button v-else type="light" @click.native="goToMyTeam()">Ver equipa</fw-button>
            </div>
            <fw-button type="xlight" @click.native="goPrevious()">{{ $t('goBack') }}</fw-button>
          </div>

          <div v-if="step != 'submit' && !loading" class="flex justify-end gap-5 text-xs my-10">
            <div class="flex-1">
              <fw-button v-if="step != 'create_team'" type="xlight" size="xs" @click.native="goPrevious()">{{
                $t('previous')
              }}</fw-button>
            </div>
            <div v-if="step == 'create_team' || step == 'personal_info'" class="text-gray-500">
              <div class="font-semibold text-right">{{ $t('nextStep') }}</div>
              {{
                step == 'create_team'
                  ? $t('provideYourDetails')
                  : step == 'personal_info'
                  ? sport?.type === 'individual'
                    ? $t('submitTeam')
                    : $t('createTeam')
                  : ''
              }}
            </div>
            <fw-button type="primary" :loading="waiting || saving" @click.native="goNext()">{{ $t('next') }}</fw-button>
          </div>

          <template v-if="step != 'submit'">
            <hr class="dropdown-divider" />
            <fw-panel
              :title="$t('registeringYourTeamIn')"
              title-size="h6"
              class="mt-5 mb-10"
              custom-class="bg-white py-5 px-10"
            >
              <BannerJUCI :tournament="tournament" :league="league" :sport="sport" :edition="edition"></BannerJUCI>
            </fw-panel>
          </template>
        </template>

        <fw-panel-info debug label="Data (raw)">
          <json-viewer
            :value="{ team, validations, teamGroups, league, edition, users, tournament, sport, userDataIsValid, step }"
          ></json-viewer>
        </fw-panel-info>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import BannerJUCI from '@/components/banners/BannerJUCI'
import PanelPersonalInformationForm from '@/components/panels/PanelPersonalInformationForm'
import CardTeam from '@/components/cards/CardTeam'
import PanelCreateTeam from '@/components/panels/PanelCreateTeam'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { required, minLength, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { GENDERS } from '@/utils/index.js'

export default {
  name: 'PanelSignupTournament',
  components: {
    PanelPersonalInformationForm,
    BannerJUCI,
    PanelCreateTeam,
    CardTeam
  },
  props: {
    userKey: {
      type: String,
      required: false
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      saving: false,
      team: {
        key: null,
        tournament_key: null,
        group_key: null,
        name: null,
        logo: null,
        leader: null,
        color: null,
        status: 'draft',
        players: [],
        submitted_at: null,
        confirmed_at: null,
        denied_at: null,
        deleted_at: null
      },
      validations: {
        can_send_message: false,
        can_send_public_message: false,
        can_cancel_team_enrollment: false,
        can_edit_team: true,
        can_see_messages: false,
        can_resend_invites: false,
        can_send_invites: false,
        can_submit: false
      },
      userDataIsValid: false,
      teamGroups: [],
      step: 'create_team',
      errors: [],
      loading: false,
      tournament: null,
      users: {},
      edition: null,
      league: null,
      sport: null,
      waiting: false,
      loadingInitial: true,
      genders: GENDERS
    }
  },
  validations() {
    return {
      team: {
        name: { required, min: minLength(1), max: maxLength(50) },
        players: { required, min: minLength(this.sport?.min_players ?? 1), max: maxLength(this.sport?.max_players) },
        group_key: {
          required: requiredIf(() => {
            return this.league?.type == 'academic'
          })
        }
      }
    }
  },
  computed: {
    isAcademic() {
      return this.league?.type == 'academic'
    },
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    firstStepDone() {
      return !this.$v.team.$invalid && !this.errors.length
    },

    secondStepDone() {
      return this.userDataIsValid
    },

    teamKey() {
      return this.$route.params.teamKey
    },

    editionKey() {
      return this.edition?.key || null
    },

    tournamentKey() {
      return this.$route.params.tournamentKey || this.team.tournament_key
    },

    isDraft() {
      return this.team && ['draft_1', 'draft_2'].includes(this.team?.status)
    },
    isIndividual() {
      return this.sport && this.sport?.type === 'individual'
    }
  },

  async mounted() {
    //we have a teamkey
    this.loadingInitial = true
    if (this.teamKey) {
      await this.getTeam()
      await this.getTeamGroups()
    } else {
      //we are creating a new team and we don't have a team key
      await Promise.all([this.getTournament(this.tournamentKey), this.getTeamGroups()])

      this.team['players'] = [this.user]
      this.team['color'] = '#734DE1'
      this.team['leader'] = this.user
    }
    setTimeout(() => {
      this.loadingInitial = false
    }, 750)
  },

  methods: {
    goPrevious() {
      if (this.step == 'personal_info') {
        this.step = 'create_team'
      } else if (this.step == 'submit') {
        this.step = 'personal_info'
      }
    },

    goToStep(step) {
      if (step != 'create_team') {
        if (!this.firstStepDone) {
          return this.$buefy.snackbar.open({
            message: 'O <b>Passo 1</b> ainda não está completo.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true
          })
        }

        if (step == 'submit' && !this.secondStepDone) {
          return this.$buefy.snackbar.open({
            message: 'O <b>Passo 2</b> ainda não está completo.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true
          })
        }
      }

      this.step = step
    },

    async goNext() {
      console.log('goNext', this.step)
      if (this.step == 'create_team') {
        this.saveTeam(Boolean(this.teamKey), true)
        if (!this.firstStepDone) return
        this.step = 'personal_info'
      } else if (this.step == 'personal_info') {
        this.waiting = true
        //verify data and submit
        let result = await this.$refs['personalinfo'].saveUserData()
        if (result && this.secondStepDone) this.step = 'submit'
        this.waiting = false
      }
    },

    goToMyTeam() {
      return this.$router.push({
        name: 'team-view',
        params: {
          teamKey: this.teamKey,
          tournamentKey: this.tournamentKey
        }
      })
    },

    goHome() {
      return this.$router.push({
        name: 'home'
      })
    },

    async getTeam() {
      if (this.teamKey) {
        this.loading = true
        try {
          const response = await this.api.getTeam(this.teamKey, {
            with_invites: true,
            with_users: true
          })
          console.log('getTeam :>> ', response)
          this.setupTeamData(response)
          if (!this.isDraft) this.goToMyTeam()
          this.getTournament(response.team.tournament_key)
        } catch (error) {
          console.log('Error getTeam :>> ', error)
        } finally {
          this.loading = false
        }
      } else {
        // add automatically the user as a player and leader
        this.team.players.push(this.user)
        this.team.leader = this.user
      }
    },

    removeLogo() {
      console.log('remove-logo')
      this.$set(this.team, 'logo', null)
    },

    selectColor(color) {
      console.log('select color :>> ', color)
      this.$set(this.team, 'color', color)
    },

    removeTeamMember(i) {
      console.log('removeTeamMember', i)
      this.team.players.splice(i, 1)
    },

    addTeamMember(teamMember) {
      console.log('addTeamMember', teamMember)
      this.team.players.push(teamMember)
      this.saveTeam(false)
    },

    updateTeamLogo(uploadedFiles) {
      const file = uploadedFiles[0]
      this.team.logo = file.response.data.file
      this.saveTeam(false)
    },

    async registerTeam() {
      console.log('registerTeam')
      console.log({ saving: this.saving, firstStepDone: this.firstStepDone, secondStepDone: this.secondStepDone })
      if (this.saving || !this.firstStepDone || !this.secondStepDone) return

      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateTeamStatus(this.team.key, 'draft_2')
          console.log('updateTeamStatus :>> ', response)

          this.$emit('team-submitted')
          this.step = 'submitted'
        },
        () => {
          this.saving = false
        }
      )
    },

    async submitTeam() {
      console.log('submitTeam')
      console.log({ saving: this.saving, firstStepDone: this.firstStepDone, secondStepDone: this.secondStepDone })
      if (this.saving || !this.firstStepDone || !this.secondStepDone) return

      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.updateTeamStatus(this.team.key, 'submitted')
          console.log('updateTeamStatus :>> ', response)

          this.$emit('team-submitted')
          this.step = 'submitted'
        },
        () => {
          this.saving = false
        }
      )
    },

    async saveTeam(validate = true, saveWithError = false) {
      if (this.saving || !this.validations.can_edit_team) return

      console.log('saveTeam :>> ', { validate, saveWithError })
      if (validate) {
        this.errors = []
        this.$v.team.$touch()
        if (this.$v.team.$error && !saveWithError) return
      }

      this.saving = true
      let data = {
        name: this.team.name,
        logo_key: this.team.logo?.key,
        color: this.team.color,
        players_emails: this.team.players.map(el => el.email),
        leader: this.team.leader?.key ?? this.team.leader?.email,
        group_key: this.team.group_key
      }

      let response
      try {
        if (!this.team.key) {
          response = await this.api.createTeam(this.tournamentKey)
          console.log('createTeam response :>> ', response)

          if (response.key) {
            this.team.key = response.key
            this.$router.push({
              name: 'team-signup-edit',
              params: { teamKey: response.key },
              replace: true
            })
            response = await this.api.updateTeam(this.team.key, data)
            this.$v.team.$touch()
            this.setupTeamData(response)
          }
        } else {
          response = await this.api.updateTeam(this.team.key, data)
          this.setupTeamData(response)
        }

        if (response?.['__errors__']) this.setTeamErrors(response['__errors__'])
      } catch (error) {
        console.error('error :>> ', error)
        if (validate) this.setTeamErrors(error.response?.data?.['__errors__'])
      } finally {
        this.saving = false
      }
    },

    setupTeamData(response) {
      if (response.users) this.users = response.users
      if (response.team?.validations) this.validations = response.team?.validations

      let players = []
      let player_keys = []
      const leader = response.team?.leader

      if (response.team.players && response.team.players.length) {
        response.team.players.forEach(player => {
          player_keys.push(player)
          players.push(this.users[player] ?? { key: player, email: player })
        })
      }

      if (response.team.invites?.invites) {
        const invites = Object.values(response.team.invites.invites)
          .flat()
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        for (const invite of invites) {
          if (player_keys.includes(invite.user_key)) continue
          players.push(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key })
        }
      }

      this.team = {
        ...response.team,
        group_key: response.team.group_key ?? null,
        players,
        leader: this.users[leader] ?? { key: leader, email: leader }
      }

      console.log('setupTeamData :>> ', this.team)
    },

    async getTeamGroups() {
      try {
        const response = await this.api.getTournamentTeamGroups(this.tournamentKey, {
          sort: 'name',
          direction: 'asc',
          with_org_units: true
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = response
      } catch (error) {
        console.log('Error getTeamGroups :>> ', error)
      }
    },

    async getTournament(tournamentKey) {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTournament(tournamentKey)
          this.tournament = response.tournament
          this.getLeagueDetails()
        },
        () => {
          this.loading = false
        }
      )
    },

    async getLeagueDetails() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getLeagueDetails(this.tournament.edition_key, this.tournament.league_key)
        this.edition = response.edition
        this.league = response.league
        this.sport = response.league.sports.find(el => el.key == this.tournament.sport_key)
      })
    },

    setTeamErrors(errorData) {
      console.error('setTeamErrors :>> ', errorData)
      let errorMessage = this.$t('errors.default')
      if (errorData && errorData.length > 0) {
        const errorKey = errorData[0].key
        console.log('errorKey :>> ', errorKey)
        switch (errorKey) {
          case 'NotFound':
            errorMessage = this.$t('errors.tournamentNotFound')
            break
          case 'InvalidOrganicUnit':
            errorMessage = this.$t('errors.invalidOrganicUnit')
            break
          case 'InvalidTournamentStage':
            errorMessage = this.$t('errors.invalidTournamentStage')
            break
          case 'SignupNotAvailable':
            errorMessage = this.$t('errors.signupNotAvailable')
            break
          case 'TeamNameAlreadyExists':
            errorMessage = this.$t('errors.teamNameAlreadyExists')
            break
          case 'Forbidden':
            errorMessage = this.$t('errors.noPermissionsToCreate')
            if (errorData[0].detail == 'Already leader of a team') {
              errorMessage = this.$t('errors.alreadyLeaderOfTeam')
            }
            break
          case 'UserAlreadyInTeam':
            errorMessage = this.$t('errors.userAlreadyInTeam', {
              email: errorData[0].email
            })
            break
          case 'MinNumber':
            errorMessage = this.$t('errors.minPlayers', {
              min_number: Number(errorData[0].min_number),
              total: this.team?.player?.length ?? 1
            })
            break
          case 'InvalidEmail':
            errorMessage = this.$t('errors.invalidEmail')
            // Only allow students in academic league. Ignore for local dev
            if (errorData[0].detail.includes('Only students')) {
              errorMessage = this.$t('errors.onlyStudents')
            }
            break

          default:
            break
        }
      }

      this.$buefy.dialog.alert({
        message: errorMessage,
        type: 'is-danger'
      })
      this.errors = errorData
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "gender": "Gender",
      "modality": "Modality",
      "tournament": "Tournament",
      "league": "League",
      "edition": "Edition",
      "teamSuccessfullySubmitted": "Team successfully submitted!",
      "teamSuccessfullyRegistered": "Team successfully registered!",
      "teamRegisteredInstructions": "Once all the invitations have been confirmed, your application can be submitted for review, where it will remain in the 'Under review' status until it is approved (or rejected) by the JUC organization.",
      "seeTeam": "See my team",
      "backHome": "Back to home page",
      "goBack": "Back",
      "step": "Step",
      "previous": "Previous",
      "next": "Next",
      "nextStep": "Next step",
      "buildYourTeam": "Build your team",
      "provideYourDetails": "Tell us your details",
      "createTeam": "Create team",
      "submitTeam": "Submit team",
      "registerTeam": "Register team",
      "wonderfulTeam": "What a marvellous team!",
      "registeringYourTeamIn": "You are registering your team in",
      "signUpTeam": "Sign up team",
      "everythingReady": "",
      "errors": {
        "default": "An error occurred when registering the team.",
        "tournamentNotFound": "The tournament could not be found.",
        "invalidOrganicUnit": "The organic unit selected is not valid.",
        "invalidTournamentStage": "The tournament is not at the registration stage.",
        "signupNotAvailable": "Registration for the tournament is not available.",
        "teamNameAlreadyExists": "There is already a team with that name.",
        "alreadyLeaderOfTeam": "You are already a team leader.",
        "noPermissionsToCreate": "You don't have permissions to create teams for this organic unit.",
        "minPlayers": "A team must have at least {min_number} players. Just {total} of {min_number} added",
        "invalidEmail": "Invalid e-mail address.",
        "onlyStudents": "Only students can be invited.",
        "userAlreadyInTeam": "The user {email} is already in a team."
      }
    },
    "pt": {
      "gender": "Género",
      "modality": "Modalidade",
      "tournament": "Torneio",
      "league": "Liga",
      "edition": "Edição",
      "teamSuccessfullySubmitted": "Equipa submetida com sucesso!",
      "teamSuccessfullyRegistered": "Equipa criada com sucesso!",
      "teamRegisteredInstructions": "Atenção! A tua equipa foi criada mas ainda não foi submetida. Depois de todos os convites confirmados e para que a tua equipa seja validada pela organização, será necessário que submetas a tua inscrição.",
      "seeTeam": "Ver a minha equipa",
      "backHome": "Voltar à página inicial",
      "goBack": "Voltar atrás",
      "step": "Passo",
      "previous": "Anterior",
      "next": "Seguinte",
      "nextStep": "Próximo passo",
      "buildYourTeam": "Constrói a tua equipa",
      "provideYourDetails": "Indica-nos os teus dados",
      "createTeam": "Criar equipa",
      "submitTeam": "Submeter equipa",
      "registerTeam": "Criar equipa",
      "wonderfulTeam": "Que equipa maravilha!",
      "registeringYourTeamIn": "Estás a inscrever a tua equipa em",
      "signUpTeam": "Criar equipa",
      "everythingReady": "",
      "errors": {
        "default": "Ocorreu um erro ao inscrever a equipa.",
        "tournamentNotFound": "O torneio não foi encontrado.",
        "invalidOrganicUnit": "A unidade orgânica selecionada não é válida.",
        "invalidTournamentStage": "O torneio não se encontra na fase de inscrições.",
        "signupNotAvailable": "As inscrições para o torneio não estão disponíveis.",
        "teamNameAlreadyExists": "Já existe uma equipa com esse nome.",
        "alreadyLeaderOfTeam": "Já é líder de uma equipa.",
        "noPermissionsToCreate": "Não tem permissão para criar equipas para esta unidade orgânica.",
        "minPlayers": "Uma equipa tem de ter pelo menos {min_number} jogadores. Apenas {total} de {min_number} adicionados",
        "invalidEmail": "E-mail inválido.",
        "onlyStudents": "Apenas podem ser convidados estudantes.",
        "userAlreadyInTeam": "O utilizador {email} já faz parte de outra equipa."
      }
    }
  }
</i18n>

<style>
.update-modal-uploader .file-uploads {
  @apply w-full mt-1;
}
</style>
