<template>
  <div
    class="rounded-xl transition-all cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
    :title="team?.name ?? $t('noName')"
  >
    <div class="flex flex-col h-full">
      <div class="flex-1 flex flex-col items-center justify-start w-full">
        <TeamAvatar :team="team" :with-shadow="true" class="mb-2" :size="size" />
        <div
          class="font-bold text-center border-2 rounded-full px-4 py-0.5 w-full"
          :style="{
            color: team.color ?? '#777777',
            borderColor: team.color ?? '#777777'
          }"
        >
          <div class="truncate min-w-0  w-full">{{ team?.name ?? $t('noName') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamAvatar from '@/components/TeamAvatar.vue'
export default {
  name: 'CardTeam',
  components: {
    TeamAvatar
  },
  props: {
    team: {
      type: Object
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "noName": "Name not defined"
    },
    "pt": {
      "noName": "Sem nome"
    }
  }

</i18n>
